<template>
  <footer v-if="document">
    <div></div>
    <div class="footer-content">
      <!-- <router-link class="privacy-policy-link" to="/privacy">{{ document.data.privacy_policy_link_text }}</router-link> -->
      <div class="copyright-notice">{{ document.data.company_name }} &copy; {{ new Date().getFullYear() }}</div>
    </div>
    <emilys-websites-footer></emilys-websites-footer>
  </footer>
</template>

<script>
import EmilysWebsitesFooter from '../EmilysWebsitesFooter/EmilysWebsitesFooter.vue';
export default {
  components: { EmilysWebsitesFooter },
  name: "kimmerle-footer",
  data() {
    return {
      document: null,
    };
  },
  methods: {
    getContent() {
      //Query to get footer content
      this.$prismic.client.getSingle("footer").then((document) => {
        if (document) {
          this.document = document;
        }
      });
    },
  },
  created() {
    this.getContent();
  }
};
</script>

<style scoped lang="scss">
footer {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: black;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * {
    flex: 1 1 100%;
    text-align: center;
  }
}

.privacy-policy-link {
  display: block;
  margin-bottom: .25em;
  color: white;
}

@media screen and (max-width:700px) {
  footer {
    flex-direction: column;
  }

  .footer-content {
    margin-bottom: 1rem;
  }
}
</style>